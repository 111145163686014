.mainWrapper {
  min-height: 100vh;
  display: flex;
  background: var(--background-strong);
  padding-bottom: 100px;
}

.formContainer {
  background: var(--background-default);
  margin: auto;
  flex-basis: 360px;
  padding: var(--mod-4-multiplier);
  border-radius: var(--defaults-borderRadius-medium);
}

.formContainerButtons {
  margin-top: var(--mod-4-multiplier);
}

.buttonWrapper {
  margin-top: var(--mod-2-multiplier);
}

.heading {
  text-align: center;
  margin: var(--mod-2-multiplier) 0 var(--mod-4-multiplier);
}

.heading-details {
  text-align: center;
}

.label {
  margin-top: var(--mod-2-multiplier);
  display: block;
}

.buttonIcon {
  display: inline-block;
  margin-right: var(--mod-1-multiplier);
}

.linksItem {
  text-align: center;
  margin-top: var(--mod-3-multiplier);
}

.message {
  padding: var(--mod-2-multiplier);
  border-radius: var(--defaults-borderRadius-small);
}

.messageFailed {
  color: var(--colors-red-medium);
  background: var(--colors-red-light);
  border: 2px solid var(--colors-red-medium);
}

.messageSuccess {
  color: var(--colors-green-medium);
  background: var(--colors-red-light);
  border: 2px solid var(--colors-green-medium);
}

.errorMessage {
  color: var(--colors-red-medium);
  background: var(--colors-red-light);
  border: 2px solid var(--colors-red-medium);
  padding: var(--mod-2-multiplier);
  border-radius: var(--defaults-borderRadius-small);
}
